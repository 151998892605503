<script lang="ts" setup>
import { RotateCcwIcon } from 'lucide-vue-next'

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  selectedEntity: {
    type: String,
    required: false,
    default: '',
  },
})
const emit = defineEmits(['close', 'load_latest_version'])
function closeModal() {
  emit('close')
}
function loadLatestVersion() {
  emit('load_latest_version')
  closeModal()
}
</script>

<template>
  <OModal
    :open="props.open"
    @close="closeModal"
  >
    <template #content>
      <div class="flex flex-col gap-4 text-primary md:gap-8">
        <div class="flex flex-col gap-1">
          <h2 class="text-xl font-semibold">
            {{ $t('global.new_version') }}
          </h2>
        </div>

        <div class="grid grid-cols-1 items-center gap-2">
          <p>{{ $t("global.click_button_reload") }}</p>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-end gap-4">
        <button class="btn-secondary" @click="closeModal">
          {{ $t('global.cancel') }}
        </button>
        <button class="btn-primary" @click="loadLatestVersion">
          <RotateCcwIcon class="mr-1" :size="18" />
          {{ $t("global.reload") }}
        </button>
      </div>
    </template>
  </OModal>
</template>
