<script setup lang="ts">
import { Box, CircleCheckIcon, MessageSquareMore, Truck } from 'lucide-vue-next'
import type { CollectGraphql, CollectStatus } from '~/types/graphql-backend-types/gql-types'
import { statusColor } from '~/utils/status'

const router = useRouter()
const { t } = useI18n()
const { loadServices } = useCollectsStore()
const { getCollects, collectsCount, filters, page, collectsLoading } = storeToRefs(useCollectsStore())

const search = ref('')
const selectedStatus = ref<CollectStatus>('' as CollectStatus)
const statusModalStatus = ref(false)
const loading = ref(false)
const id = ref('')

function goToService(id: any) {
  const to = id || 'new'
  router.push(`/services/${to}`)
}

function getIconForTypeId(typeId: string) {
  switch (typeId) {
    case 'bin_collection':
      return Truck
    default:
      return MessageSquareMore
  }
}

function formatText(text: any) {
  text = text.charAt(0).toUpperCase() + text.slice(1)
  return text.replace(/_/g, ' ')
}

function editService(id = '') {
  if (!id)
    return
  goToService(id)
}

function openModal(modalId: string, status: CollectStatus) {
  id.value = modalId
  selectedStatus.value = status
  statusModalStatus.value = true
}

function onSearch(searchTerm: string) {
  search.value = searchTerm
}

onMounted(async () => {
  await loadServices()
})
</script>

<template>
  <div class="flex flex-col grow h-[calc(100%-84px)]">
    <!-- Search and filters -->
    <div class="z-2 flex-shrink-0 flex flex-col items-start gap-2 pl-4 md:flex-row md:items-center mb-4">
      <div class="w-full flex lg:min-w-80 lg:w-1/4">
        <OSearchBar v-model="search" @update:model-value="onSearch" />
      </div>
      <div class="flex flex-col gap-2 md:flex-row">
        <RecollectionSortingPopOver v-model="filters.sort" class="h-8.5" />
        <RecollectionStatusPopOver v-model="filters.statuses" class="h-8.5" />
        <RecollectionClientPopOver v-model="filters.client" class="h-8.5" />
      </div>
    </div>

    <!-- Scrollable grid container -->
    <div class="flex-grow overflow-hidden h-56">
      <div v-if="!collectsLoading" class="h-full overflow-auto">
        <div class="mx-auto mb-4 max-w-[1500px] min-h-full w-full gap-2 rounded-md p-4 md:min-w-[500px] md:p-6">
          <OGrid
            :columns="[
              { key: 'type_id', label: $t('global.category'), width: 2, labelClass: 'text-start' },
              {
                key: 'container',
                label: $t('global.content'),
                width: 2,
                labelClass: 'text-start',
              },
              { key: 'producer', label: $t('global.client'), width: 2, labelClass: 'text-center w-full justify-center' },
              {
                key: 'endsAt',
                label: $t('global.service_date'),
                width: 2,
                labelClass: 'text-center w-full justify-center',
              },
              {
                key: 'status',
                label: $t('global.status'),
                width: 2,
                labelClass: 'text-end flex w-full justify-end items-center gap-1',
              },
            ]"
            :rows="getCollects"
            :loading="loading"
            @click="editService($event.id)"
          >
            <template #type_id="{ row }: { row: CollectGraphql }">
              <div class="col-span-2 w-full flex items-center justify-start gap-2">
                <div class="rounded-full bg-[#E5E7EB] p-1 text-[#4B5563]">
                  <component :is="getIconForTypeId('bin_collection')" :size="18" />
                </div>
                <p class="whitespace-nowrap">
                  {{ formatText('bin_collection') }}
                </p>
              </div>
            </template>
            <template #container="{ row }: { row: CollectGraphql }">
              <template v-if="row?.collectedContainers?.collection?.length > 0">
                <div class="w-full flex items-center justify-start gap-1.5">
                  <div v-for="(container, index) in row.collectedContainers.collection" :key="index">
                    <div
                      v-if="index <= 0"
                      class="grid grid-flow-col w-fit items-center justify-start gap-1 rounded-sm bg-[#F3F4F6] p-1 pr-1.5"
                    >
                      <div class="w-full flex items-center justify-center">
                        <!-- <img
                          v-if="isNotNullOrEmptyArray(container?.attachments)"
                          :src="container?.attachments[0].signed_url"
                          class="h-6 w-6 rounded-sm object-cover"
                        > -->
                        <!-- <img
                          v-else-if="container?.material.photo_url"
                          :src="container.material.photo_url"
                          class="h-6 w-6 rounded-sm object-cover"
                        > -->
                        <Box :size="18" />
                      </div>
                      <p class="truncate text-[#4B5563]">
                        {{ container?.material.name }}
                      </p>
                    </div>
                    <div v-if="row.collectedContainers.collection.length > 1 && index === 1">
                      <p class="text-zinc-300">
                        +{{ row.collectedContainers.collection.length - 1 }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <p class="flex items-center justify-center text-gray-400 italic">
                  {{ t("global.no_materials") }}
                </p>
              </template>
            </template>
            <template #producer="{ row }: { row: CollectGraphql }">
              <div class="w-full justify-center">
                <p class="line-clamp-1 text-center text-[#374151]">
                  {{ row.client.name }}
                </p>
              </div>
            </template>
            <template #endsAt="{ row }: { row: CollectGraphql }">
              <div class="w-full flex items-center justify-center text-[#374151]">
                {{
                  new Date(row.endsAt).toLocaleDateString("fr-FR", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                  })
                }}
              </div>
            </template>
            <template #status="{ row }: { row: CollectGraphql }">
              <div class="w-full flex items-center justify-end gap-1">
                <Tag
                  :tag="$t(`collect.status_${row.status}`)"
                  :tag-classes="statusColor.find((status) => status.status === row.status)"
                />
                <div
                  class="group rounded-lg bg-[#E5E7EB] p-1.5 transition-all hover:bg-green-100"
                  @click.stop.prevent="openModal(row.id, row.status)"
                >
                  <CircleCheckIcon :size="14" color="#595959" class="group-hover:text-green-900" />
                </div>
              </div>
            </template>
          </OGrid>
        </div>
      </div>
      <div v-else class="flex grow items-center justify-center">
        <Loader class="mb-2 h-8 w-8 text-gray-500" />
      </div>
    </div>

    <!-- Pagination at the bottom -->
    <div class="flex-shrink-0 mt-4 mb-4">
      <OPagination v-model="page" :number-page="Math.ceil(collectsCount / 10)" />
    </div>
  </div>
  <StatusModal
    :id="id"
    v-model="statusModalStatus"
    :state="selectedStatus"
    @update:status="selectedStatus = $event"
    @close="statusModalStatus = false"
  />
</template>
