import { gql } from '@apollo/client/core'

export const GET_TREATMENT_CODE = gql`
  query GetTreatmentCode {
    treatmentCodes {
      code
      description
      treatmentKind
    }
  }
`
export const GET_WASTE_CODE_EU = gql`
  query GetWasteCodeEU {
    wasteCodes {
      categoryCode
      categoryDescription
      subCategories {
        subCategoryCode
        subCategoryDescription
        codes {
          code
          description
        }
      }
    }
  }
`
