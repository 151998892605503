import { gql } from '@apollo/client/core'

export const RAW_MATERIALS_DATA_QUERY_LIST = gql`
  query RawMaterials {
    raw_materials_containers {
      raw_materials_id
      name
      quantity
      photo_url
      id
      container_type
      unit
      client_id
      site_id
      _updated_by
      _updated_at
      _created_by
      _created_at
      raw_material {
        name
      }
      site {
        name
      }
    }
  }
`

export const RAW_MATERIALS_CONTAINERS_QUERY_GET = gql`
  query RawMaterialContainers($id: Int!) {
    raw_materials_containers(where: { raw_materials_id: { _eq: $id } }) {
      raw_materials_id
      quantity
      photo_url
      container_type
      unit
      id
      name
      client_id
      attachments
      site_id
      _updated_by
      _updated_at
      _created_by
      _created_at
      raw_material {
        name
      }
    }
  }
`

export const RAW_MATERIALS_CONTAINERS_QUERY_GET_BY_CLIENT_ID = gql`
  query RawMaterialContainers($client_id: Int!) {
    raw_materials_containers(where: { client_id: { _eq: $client_id } }) {
      client_id
      id
      name
      photo_url
      container_type
      unit
      attachments
      quantity
      raw_materials_id
      site_id
      raw_material {
        name
      }
      site {
        name
      }
    }
  }
`

export const RAW_MATERIALS_DATA_QUERY_GET_BY_CLIENT_ID = gql`
  query RawMaterial($client_id: Int!) {
    raw_materials_container(where: { raw_materials_id: { _eq: $client_id } }) {
      raw_materials_id
      quantity
      photo_url
      container_type
      unit
      id
      name
      _updated_by
      _updated_at
      _created_by
      _created_at
    }
  }
`
export const RAW_MATERIALS_DATA_QUERY_UPDATE = gql`
  mutation UpdateRawMaterialsData($id: Int!, $object: raw_materials_containers_set_input!) {
    update_raw_materials_containers_by_pk(pk_columns: { id: $id }, _set: $object) {
      name
      quantity
      photo_url
      site_id
      id
      container_type
      unit
    }
  }
`
export const RAW_MATERIALS_CONTAINERS_DATA_QUERY_INSERT = gql`
  mutation InsertRawMaterialContainers($object: raw_materials_containers_insert_input!) {
    insert_raw_materials_containers_one(object: $object) {
      raw_materials_id
      name
      quantity
      photo_url
      id
      site_id
      container_type
      unit
    }
  }
`
export const RAW_MATERIALS_DATA_QUERY_DELETE = gql`
  mutation DeleteRawMaterial($id: Int!) {
    delete_waste_service_raw_materials_containers(where: {raw_materials_container_id: {_eq: $id}}){
      affected_rows
    }
    delete_raw_materials_containers_by_pk(id: $id) {
      raw_materials_id
      quantity
      photo_url
      id
      name
      _updated_by
      _updated_at
      _created_by
      _created_at
    }
  }
`

export const RAW_MATERIALS_QUERY_DELETE_BY_MATERIAL_ID = gql`
  mutation DeleteRawMaterialByMatrialId($id: Int!) {
    delete_collection_coupon_raw_materials_container(
      where: { raw_materials_container: { raw_materials_id: { _eq: $id } } }
    ) {
      affected_rows
    }
    delete_raw_materials_containers(where: { raw_materials_id: { _eq: $id } }) {
      affected_rows
    }
    delete_raw_materials(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`

export const GET_CONTAINERS_TYPES = gql`
  query MyQuery {
    standard_containers {
      value
    }
  }
`
