<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ArrowDownZAIcon, ArrowUpAZIcon } from 'lucide-vue-next'
import { AscDescGraphql } from '~/types/graphql-backend-types/gql-types'

const model = defineModel()

function updateModelValue(value: string, close = () => {}) {
  model.value = value
  close()
}
</script>

<template>
  <Popover v-slot="{ close, open }" class="relative flex-shrink-0">
    <PopoverButton
      class="h-full gap-1.5 px-2 py-1 text-sm btn-secondary"
      :class="open ? '!border-[#373FEF]' : '!hover:text-secondary'"
    >
      <ArrowUpAZIcon v-if="model === AscDescGraphql.Asc" color="#40403F" :size="18" />
      <ArrowDownZAIcon v-else color="#40403F" :size="18" />
      <div class="flex items-center gap-1 text-[#262626]">
        <p class="font-semibold">
          {{ `${$t('global.sort')} : ` }}
        </p>
        <p>
          {{ model === AscDescGraphql.Desc ? $t('global.more_recent') : $t('global.more_ancient') }}
        </p>
      </div>
    </PopoverButton>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <PopoverPanel class="absolute left-0 top-9 z-10 max-w-50 w-screen flex">
        <div
          class="w-50 flex-auto overflow-hidden rounded bg-white text-sm leading-2 ring-1 ring-gray-900/5"
          style="box-shadow: 0 2px 6px 2px rgba(60, 64, 67, 0.15)"
        >
          <div class="flex flex-col py-2">
            <button
              class="w-full flex cursor-pointer items-center gap-2 px-3 py-1.5 text-sm text-[#43474F] hover:bg-[#DADCE080]"
              :class="model === AscDescGraphql.Desc ? 'bg-[#DADCE080]' : ''"
              @click="updateModelValue(AscDescGraphql.Desc, close)"
            >
              <ArrowDownZAIcon color="#40403F" :size="18" />
              {{ $t("global.more_recent") }}
            </button>
            <button
              class="w-full flex cursor-pointer items-center gap-2 px-3 py-1.5 text-sm text-[#43474F] hover:bg-[#DADCE080]"
              :class="model === AscDescGraphql.Asc ? 'bg-[#DADCE080]' : ''"
              @click="updateModelValue(AscDescGraphql.Asc, close)"
            >
              <ArrowUpAZIcon color="#40403F" :size="18" />
              {{ $t("global.more_ancient") }}
            </button>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
