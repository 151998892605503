<script setup lang="ts">
import { generateTagStyleFromString } from '~/controllers/colors'
import type { TagClasses } from '~/types/tag'

/**
 * @tag : String
 * (The tag content to display, can be overriden by the slot content)
 *
 * @tagClasses : TagClasses
 * (The classes to apply to the tag)
 */
const props = defineProps({
  tag: {
    type: String,
    required: false,
    default: '',
  },
  tagClasses: {
    type: Object as PropType<TagClasses>,
    required: false,
    default: null,
  },
})
</script>

<template>
  <div
    :class="[generateTagStyleFromString(props?.tag), props.tagClasses?.tag]"
    class="w-fit flex items-center gap-1 truncate rounded px-1.5 py-0.5 text-sm"
  >
    <slot>
      <p :class="props.tagClasses?.label">
        {{ props.tag }}
      </p>
    </slot>
  </div>
</template>
