<script setup lang="ts">
import { Calendar } from 'lucide-vue-next'
import type { CollectStatus } from '~/types/graphql-backend-types/gql-types'

// TODO: Wait for feature/pro-47-mutation-to-update-collect to pass a CollectGraphQL as props and refacto component
const props = defineProps({
  service: {
    type: Object,
    required: true,
    default: null,
  },
  index: {
    type: Number,
    required: true,
    default: 0,
  },
})
const { getUsersAsOptions } = storeToRefs(useUsersStore())
const isLoading = ref<boolean>(false)
const selectedStatus = ref<CollectStatus>() as Ref<CollectStatus>
const selectedReferent = ref<string>('')
const referentOptions = ref<string[]>([])

const data = reactive({
  service: null as Record<string, any> | null,
  notes: '',
  referent: [] as any[],
})

const { t } = useI18n()

function loadData() {
  isLoading.value = true

  data.service = props.service
  selectedStatus.value = data.service.data.status

  const referent = getUsersAsOptions.value
  referentOptions.value = referent
  selectedReferent.value = data?.service?.data?.referent

  data.notes = data?.service?.data?.notes
  isLoading.value = false
}

onMounted(() => {
  loadData()
})
</script>

<template>
  <!-- TODO : Wait for materials documents on query 'collects' -->
  <template v-if="!isLoading && data.service !== null">
    <div
      v-if="data?.service?.waste_service_raw_materials_containers?.length > 0"
      class="flex items-start gap-4 border border-[#F5F5F5] rounded-lg bg-white p-4"
    >
      <img
        v-if="data?.service?.waste_service_raw_materials_containers[0]?.raw_materials_container?.raw_material.attachments[0]?.thumbnail_url"
        class="h-11 w-11 rounded"
        :src="data?.service?.waste_service_raw_materials_containers[0]?.raw_materials_container?.raw_material.attachments[0]?.thumbnail_url"
        :alt="data?.service?.waste_service_raw_materials_containers[0]?.raw_materials_container?.raw_material.name"
      >
      <img
        v-if="data?.service?.waste_service_raw_materials_containers[0]?.raw_materials_container?.photo_url"
        class="h-11 w-11 rounded"
        :src="data?.service?.waste_service_raw_materials_containers[0]?.raw_materials_container?.photo_url"
        :alt="data?.service?.waste_service_raw_materials_containers[0]?.raw_materials_container?.raw_material.name"
      >
      <img
        v-else
        class="h-11 w-11 rounded"
        :src="data?.service?.waste_service_raw_materials_containers[0]?.raw_materials_container?.raw_material.photo_url"
        :alt="data?.service?.waste_service_raw_materials_containers[0]?.raw_materials_container?.raw_material.name"
      >

      <div class="w-full flex flex-col items-start gap-2">
        <div class="w-full flex justify-between">
          <div class="flex items-center gap-1">
            <p class="text-[#262626] font-semibold">
              {{ data.service.waste_service_raw_materials_containers[0].raw_materials_container?.raw_material.name }}
            </p>
            <p class="flex items-center gap-1">
              <span class="whitespace-nowrap rounded bg-[#F2F2FF] px-1.5 py-0.5 text-sm text-[#3019BE]">
                {{
                  data.service.waste_service_raw_materials_containers[props.index].raw_materials_container.name
                    ? `${data.service.waste_service_raw_materials_containers[props.index].raw_materials_container.name}`
                    : `${t("global.bin")} ${data.service.waste_service_raw_materials_containers[props.index].raw_materials_container.quantity} L`
                }}
              </span>
            </p>
          </div>
        </div>
        <div class="w-full flex gap-2 text-sm text-[#8C8C8C]">
          <div class="flex items-center gap-1">
            <Calendar :size="18" />
            <span>  {{
                      new Intl.DateTimeFormat("en-GB", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour12: false,
                        timeZone: "UTC",
                      }).format(new Date(data.service.start_datetime))
                    }}
              {{ $t("global.between") }}
              {{
                new Intl.DateTimeFormat("en-GB", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                  timeZone: "UTC",
                }).format(new Date(data.service.start_datetime))
              }}h
              {{ $t("global.and") }}
              {{
                new Intl.DateTimeFormat("en-GB", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                  timeZone: "UTC",
                }).format(new Date(data.service.end_datetime))
              }}h
            </span>
          </div>
        </div>

        <div class="w-full flex items-center gap-3">
          <div class="w-1/2 flex items-center gap-1.5">
            <ProgressBar :progress="data.service?.waste_service_raw_materials_containers[props.index]?.fill_rate" />
          </div>
          <!-- TODO : image du container / gain estimé -->
          <div
            v-if="data.service?.waste_service_raw_materials_containers[props.index]?.attachments?.length > 0"
            class="flex items-center gap-2"
          >
            <div
              v-for="picture in data.service?.waste_service_raw_materials_containers[props.index]?.attachments"
              :key="picture"
            >
              <img :src="picture.signed_url" class="h-8 w-8 rounded object-cover">
            </div>
          </div>

          <!-- <p class="whitespace-nowrap text-xs text-[#8C8C8C]">Gain estimé : 500€ </p> -->
        </div>
      </div>
    </div>
  </template>
</template>
