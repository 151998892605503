import { gql } from '@apollo/client/core'

export const RAW_MATERIALS_QUERY_LIST = gql`
  query RawMaterials {
    raw_materials {
      id
      _created_at
      _updated_at
      _created_by
      _updated_by
      client_id
      site_id
      waste_code_eu
      consistency
      name
      price
      photo_url
      is_sold
      cap_code
      prices_lists {
        id
        end_date
        price
        rm_id
        start_date
        status
      }
      client {
        name
      }
      raw_material_wrs {
        recycler_id
        recycler {
          id
          name
          attachments
          client_type
        }
      }
      raw_materials_containers {
        site_id
        raw_materials_id
        quantity
        photo_url
        name
        id
        client_id
        unit
        container_type
        attachments
      }
    }
  }
`

export const RAW_MATERIALS_RECYCLER_QUERY_LIST = gql`
  query RawMaterials {
    raw_materials(where: { raw_materials_containers_aggregate: { count: { predicate: { _gt: 0 } } } }) {
      id
      _created_at
      _updated_at
      _created_by
      _updated_by
      client_id
      name
      price
      photo_url
      waste_code_eu
      consistency
      site_id
      cap_code
      prices_lists {
        id
        end_date
        price
        rm_id
        start_date
        status
      }
      client {
        name
      }
    }
  }
`

export const RAW_MATERIALS_BY_SITE_QUERY = gql`
  query RawMaterials($site_id: Int!) {
    raw_materials(
      where: {
        raw_materials_containers_aggregate: { count: { predicate: { _gt: 0 }, filter: { site_id: { _eq: $site_id } } } }
      }
    ) {
      id
      _created_at
      _updated_at
      _created_by
      _updated_by
      client_id
      waste_code_eu
      cap_code
      consistency
      name
      price
      photo_url
      client {
        name
      }
      prices_lists {
        id
        end_date
        price
        rm_id
        start_date
        status
      }
    }
  }
`

export const RAW_MATERIALS_QUERY_GET = gql`
  query RawMaterial($id: Int!) {
    raw_materials(where: { client_id: { _eq: $id } }) {
      id
      name
      price
      photo_url
      client_id
      is_sold
      consistency
      waste_code_eu
      cap_code
      _updated_by
      _updated_at
      _created_by
      _created_at
    }
  }
`

export const RAW_MATERIALS_QUERY_GET_BY_ID = gql`
  query RawMaterial($id: Int!) {
    raw_materials_by_pk(id: $id) {
      id
      name
      price
      photo_url
      client_id
      is_sold
      waste_code_eu
      cap_code
      consistency
      _updated_by
      _updated_at
      _created_by
      _created_at
      raw_material_wrs {
        id
        raw_material_id
        site_id
        site {
          client_id
        }
      }
    }
  }
`

// export const RAW_MATERIALS_QUERY_INSERT = gql`
//   mutation InsertRawMaterial($object: raw_materials_insert_input!) {
//     insert_raw_materials_one(object: $object) {
//       id
//       _created_at
//       _updated_at
//       _created_by
//       _updated_by
//       name
//       price
//       photo_url
//       client_id
//       raw_material_wrs {
//         site_id
//       }
//     }
//   }
// `;

export const RAW_MATERIALS_QUERY_INSERT = gql`
  mutation InsertRawMaterial($object: raw_materials_insert_input!) {
    insert_raw_materials_one(object: $object) {
      id
      _created_at
      _updated_at
      _created_by
      _updated_by
      is_sold
      name
      photo_url
      cap_code
      client_id
      site_id
      waste_code_eu
      consistency
      raw_material_wrs {
        raw_material_id
        recycler_id
        recycler {
          id
          name
          attachments
          client_type
        }
      }
    }
  }
`

export const INSERT_RAW_MATERIALS_WR = gql`
  mutation InsertRawMaterialWr($object: [raw_material_wr_insert_input!]!) {
    insert_raw_material_wr(objects: $object) {
      affected_rows
      returning {
        recycler_id
        recycler {
          id
          name
          attachments
          client_type
        }
      }
    }
  }
`

export const RAW_MATERIALS_QUERY_UPDATE = gql`
  mutation UpdateRawMaterial($id: Int!, $object: raw_materials_set_input!, $wrsData: Int!) {
    update_raw_materials_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      name
      price
      photo_url
      is_sold
      client_id
      waste_code_eu
      cap_code
      consistency
    }
    update_raw_material_wr(where: { raw_material_id: { _eq: $id } }, _set: { recycler_id: $wrsData }) {
      affected_rows
      returning {
        recycler_id
        recycler {
          id
          name
          attachments
          client_type
        }
      }
    }
  }
`

export const RECYCLER_MATERIALS_UPDATE = gql`
  mutation UpdateRecyclerMaterials($id: Int!, $site_id: Int!) {
    update_raw_material_wr_by_pk(pk_columns: { id: $id }, _set: { site_id: $site_id }) {
      site_id
    }
  }
`

export const RAW_MATERIALS_QUERY_DELETE = gql`
  mutation DeleteRawMaterial($id: Int!) {
    delete_waste_service_raw_materials_containers(
      where: { raw_materials_container: { raw_materials_id: { _eq: $id } } }
    ) {
      affected_rows
    }
    delete_waste_service(
      where: { waste_service_raw_materials_containers: { raw_materials_container: { raw_materials_id: { _eq: $id } } } }
    ) {
      affected_rows
    }
    delete_raw_materials_containers(where: { raw_materials_id: { _eq: $id } }) {
      affected_rows
    }
    delete_raw_materials_by_pk(id: $id) {
      id
      _created_at
      _updated_at
      _created_by
      _updated_by
      name
      price
      photo_url
    }
  }
`

export const RAW_MATERIALS_QUERY_GET_WITH_CONTAINERS = gql`
  query RawMaterial($id: Int!) {
    raw_materials(where: { client_id: { _eq: $id } }) {
      id
      name
      price
      photo_url
      client_id
      consistency
      waste_code_eu
      cap_code
      _updated_by
      _updated_at
      _created_by
      _created_at
      raw_materials_containers {
        id
        photo_url
        quantity
      }
    }
  }
`

// export const INSERT_RAW_MATERIAL_WITH_RECYCLERS = gql`
// mutation InsertRawMaterialWithRecyclers($objects: [raw_materials_recyclers_insert_input!]!) {

// }
// `;
