import { ApolloClient, HttpLink, InMemoryCache, concat } from '@apollo/client/core'
import { getBackEndURL, getGraphQLdURL } from '~/config'
import { fb_auth } from '~/services/firebase'
import { adminTokenStore } from '~/stores/adminToken'

const AUTH_TOKEN = 'apollo-token'

export async function getToken(): Promise<string | undefined> {
  const user: any = fb_auth.currentUser
  const token: string | undefined = await user?.getIdToken()
  if (token)
    localStorage.setItem(AUTH_TOKEN, token)
  return token
}
console.log('getGraphQLdURL', getGraphQLdURL())
const httpLink = new HttpLink({ uri: `${getBackEndURL()}/graphql` })

const authMiddleware: any = async (operation: any, forward: any) => {
  // add the authorization to the headers
  // const token = await getToken();
  const token = adminTokenStore().overlayedUserToken ? adminTokenStore().overlayedUserToken : await getToken()
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : 'token bad',
    },
  })
  return forward(operation)
}

const apolloClientMicroOrm = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})

export default apolloClientMicroOrm
