<script setup lang="ts">
import { Check } from 'lucide-vue-next'
/**
 * @progress : Number
 * (The progress of the bar : 0 to 100)
 *
 * @color : String (hex)
 * (The color of the bar)
 *
 * @backgroundColor : String (hex)
 * (The background color of the bar)
 */
const props = defineProps({
  progress: {
    type: Number,
    required: true,
  },
  color: {
    type: String,
    required: false,
  },
  backgroundColor: {
    type: String,
    required: false,
  },
})
const progressColor = computed(() => {
  if (props.color)
    return props.color
  switch (true) {
    case props.progress < 10:
      return '#991B1B'
    case props.progress < 20:
      return '#B91C1C'
    case props.progress < 30:
      return '#DC2626'
    case props.progress < 40:
      return '#EA580C'
    case props.progress < 50:
      return '#F97316'
    case props.progress < 60:
      return '#EAB308'
    case props.progress < 70:
      return '#FACC15'
    case props.progress < 80:
      return '#22C55E'
    case props.progress < 90:
      return '#16A34A'
    case props.progress < 100:
      return '#15803D'
    default:
      return '#16A34A'
  }
})
</script>

<template>
  <div class="h-full w-full flex items-center gap-2">
    <div
      class="h-2 w-full flex justify-start rounded"
      :style="{ backgroundColor: props.backgroundColor ?? '#E5E5E5' }"
    >
      <div
        :style="{ width: `${props.progress}%`, backgroundColor: progressColor }"
        class="h-full rounded"
      />
    </div>
    <p v-if="props.progress <= 100" class="whitespace-nowrap text-sm text-[#9A9A98]">
      {{ `${props.progress}%` }}
    </p>
    <Check v-else color="#16A34A" />
  </div>
</template>
