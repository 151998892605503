import { defineStore } from 'pinia'
import { provideApolloClient } from '@vue/apollo-composable'
import apolloClient from '../composables/graphql'
import { DECLARE_INCIDENT_ON_COLLECT, DELETE_INCIDENT, INCIDENTS_QUERY_LIST, INSERT_INCIDENT, UPDATE_INCIDENT, UPDATE_INCIDENT_ON_COLLECT } from '~/queries/incidents'
import type { Incident } from '~/types/HasuraInterface/incidents'
import type { DeclareIncidentGraphqlInput, UpdateIncidentGraphqlInput } from '~/types/graphql-backend-types/gql-types'

provideApolloClient(apolloClient)

export const incidentStore = defineStore({
  id: 'incidents',
  state: () => ({
    incidents: {
      list: [] as Array<Incident>,
    },
  }),
  getters: {
    getIncidents(): Incident[] {
      return this.incidents.list
    },
  },
  actions: {
    getIncident(id: string): any {
      return this.incidents.list.find(incident => incident.id.toString() === id.toString())
    },

    async loadIncidents(): Promise<any> {
      const incidents = await apolloClient.query({ query: INCIDENTS_QUERY_LIST })
      this.incidents.list = incidents.data.incidents
      return incidents.data.incidents
    },

    async deleteIncident(id: string): Promise<any> {
      await apolloClient.mutate({
        mutation: DELETE_INCIDENT,
        variables: {
          id,
        },
      })
      this.incidents.list = this.incidents.list.filter(item => item.id !== id)
    },

    async updateIncident(incident: any): Promise<any> {
      delete incident.__typename
      const updatedIncident = await apolloClient.mutate({
        mutation: UPDATE_INCIDENT,
        variables: {
          id: incident.id,
          object: incident,
        },
      })
      this.incidents.list = [
        ...this.incidents.list.filter(item => item.id !== incident.id),
        updatedIncident.data.update_incidents_by_pk,
      ]
    },

    async insertIncident(incident: any): Promise<any> {
      const insertedIncident = await apolloClient.mutate({
        mutation: INSERT_INCIDENT,
        variables: {
          object: incident,
        },
      })
      this.incidents.list = [...this.incidents.list, insertedIncident.data.insert_incidents_one]
    },

    // RECYCLER
    getIncidentByServiceId(id: string): Incident[] {
      return this.incidents.list.filter(item => item.waste_service_id.toString() === id)
    },
    declareIncidentOnCollect(incident: DeclareIncidentGraphqlInput): Promise<any> {
      const { mutate } = useGqlMikro()

      return mutate({ mutation: DECLARE_INCIDENT_ON_COLLECT, variables: { input: incident } })
    },

    updateIncidentOnCollect(incident: UpdateIncidentGraphqlInput): Promise<any> {
      const { mutate } = useGqlMikro()

      return mutate({ mutation: UPDATE_INCIDENT_ON_COLLECT, variables: { input: incident } })
    },
  },
})
