import qs from 'qs'
import axios from 'axios'

import { getBackEndURL } from '~/config/index'
import { fb_auth } from '~/services/firebase'

export const apiConfig = {
  returnRejectedPromiseOnError: true,
  timeout: 60000,
  baseURL: getBackEndURL(),
  paramsSerializer: {
    encode: (params: any) => qs.stringify(params, { indices: false }),
  },
}

export default class ApiHelper {
  apiClient: any
  apiClientPython: any
  userTokenId: string
  fb_auth: any

  constructor() {
    this.apiClient = axios.create(apiConfig)
    this.fb_auth = fb_auth
    this.userTokenId = ''
  }

  setUserTokenId(userTokenId: string) {
    this.userTokenId = userTokenId
  }

  async getToken() {
    const user: any = await this.fb_auth?.currentUser
    const token: string = await user?.getIdToken()
    this.setUserTokenId(token)
  }

  getAnonymousHeaders() {
    const h = {
      headers: {
        common: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      },
    }
    return h
  }

  async getHeaders(content_type = 'application/json') {
    await this.getToken()
    const h = {
      headers: {
        common: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Content-Type': content_type,
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.userTokenId}`,
        },
      },
    }
    return h
  }

  async getStatus() {
    const resultAxios: any = await this.apiClient.get('/v1/status', this.getAnonymousHeaders())
    return resultAxios?.data
  }

  async getUsers() {
    const resultAxios: any = await this.apiClient.get('/v1/users', await this.getHeaders())
    return resultAxios?.data
  }

  async getUser(id: string) {
    const resultAxios: any = await this.apiClient.get(`/v1/user/${id}`, await this.getHeaders())
    return resultAxios?.data
  }

  async postUser(id: string, data: any) {
    const resultAxios: any = await this.apiClient.post(`/v1/user/${id}`, data, await this.getHeaders())
    return resultAxios?.data
  }

  async deleteFile(id: string) {
    const resultAxios: any = await this.apiClient.delete(`/v1/files/${id}`, await this.getHeaders())
    return resultAxios?.data
  }

  async getFile(id: string) {
    const resultAxios: any = await this.apiClient.get(`/v1/files/${id}`, await this.getHeaders())
    const linkSource = resultAxios?.data?.data
    const fileName = resultAxios?.data?.file_name
    const base64Response = await fetch(linkSource)
    const blob = await base64Response.blob()
    const downloadLink = document.createElement('a')
    downloadLink.href = URL.createObjectURL(blob)
    downloadLink.download = fileName
    document.body.append(downloadLink)
    downloadLink.click()
    downloadLink.remove()
    return resultAxios?.data
  }

  async uploadFile(fileName: string, fileData: any, thumbnail = false, thumbnail_size = 200) {
    await this.getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${this.userTokenId}`)
    const formdata = new FormData()
    formdata.append('file', fileData, fileName)

    const requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    }
    const request: any = {
      response: '',
      result: {},
      error: {},
    }

    if (thumbnail) {
      const response = await fetch(
        `${getBackEndURL()}/v1/files?thumbnail=${thumbnail}&thumbnail_size=${thumbnail_size}`,
        requestOptions,
      ).catch(error => (request.error = error))

      request.result = await response.json()
      return request
    }
    else {
      const response = await fetch(`${getBackEndURL()}/v1/files`, requestOptions).catch(
        error => (request.error = error),
      )
      request.result = await response.json()
      return request
    }
  }

  async renderPdf(data: any) {
    const resultAxios: any = await this.apiClient.post('/v1/pdf', data, {
      ...(await this.getHeaders()),
      responseType: 'blob',
    })
    return resultAxios?.data
  }

  async sendTrackDechetsCode(code: string) {
    const resultAxios: any = await this.apiClient.post('/integrations/trackdechets/callback', { code }, await this.getHeaders())
    return resultAxios?.data
  }
  // START OF GRAPHQL QUERIES
}
