<script setup lang="ts">
import { PencilIcon } from 'lucide-vue-next'
import type { CollectGraphql } from '~/types/graphql-backend-types/gql-types'
import { statusColor } from '~/utils/status'

const router = useRouter()

const { t } = useI18n()
const { collectsCount, collects, filters, page, collectsLoading } = storeToRefs(useCollectsStore())
const { loadBOCollects } = useCollectsStore()

const columns = [
  { key: 'id', label: t('global.id'), searchable: true },
  { key: 'startsAt', label: t('global.pickup_date'), searchable: true },
  { key: 'client', label: t('global.producer'), searchable: true },
  { key: 'recycler', label: t('global.recycler'), searchable: true },
  { key: 'type', label: t('global.type'), searchable: true },
  { key: 'status', label: t('global.status'), searchable: true },
  { key: 'material', label: t('global.materials'), searchable: false },
  { key: 'containers', label: t('global.containers'), searchable: false },
  { key: 'createdAt', label: t('global.created_at'), searchable: false },
]

function goToService(id: any) {
  const to = id || 'new'
  router.push(`/services/${to}`)
}

const selectedClient = computed(() => {
  return orgStore().getSelectedOrg
})

watch(selectedClient, () => {
  loadBOCollects()
})
function editService(id = '') {
  if (!id)
    return
  goToService(id)
}

onMounted(async () => {
  loadBOCollects()
})
</script>

<template>
  <section v-if="!collectsLoading" class="w-full flex flex-col gap-4 rounded-md bg-white p-4 md:p-6">
    <div class="flex items-center gap-2">
      <RecollectionSortingPopOver v-model="filters.sort" />
      <RecollectionStatusPopOver v-model="filters.statuses" />
    </div>
    <OTable
      hide-rows-selection
      :columns="columns"
      :has-search-bar="false"
      :rows="collects.collection"
      :sortable="true"
    >
      <template #cells="{ row, col }: { row: CollectGraphql, col: { key: string, label: string, searchable: boolean } }">
        <div v-if="col.key === 'status'">
          <!-- <Tag :tag="formatText(row[col.key])" /> -->
          <Tag
            :tag="t(`collect.status_${row[col.key]}`)"
            :tag-classes="statusColor.find((item) => item.status === row.status)"
          />
        </div>
        <div v-if="col.key === 'type'">
          <!-- <Tag :tag="formatText(row[col.key])" /> -->
          <Tag :tag="t(`global.bin_collection`)" />
        </div>
        <div v-if="col.key === 'startsAt'">
          {{
            new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
              timeZone: "UTC",
            }).format(new Date(row[col.key]))
          }}
        </div>
        <div v-if="col.key === 'createdAt'">
          {{
            new Date(row[col.key]).toLocaleDateString("fr-FR", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          }}
        </div>
        <div v-if="col.key === 'transportPrice'">
          {{ row.transportPrice }} €
        </div>
        <div v-if="col.key === 'client'">
          {{ row?.client?.name }}
        </div>
        <div v-if="col.key === 'recycler'">
          <p v-if="row[col.key]">
            {{ row?.recycler?.name }}
          </p>
          <p v-else class="text-zinc-500 italic">
            {{ t("global.no_recycler_defined_for_the_moment") }}
          </p>
        </div>

        <div v-if="col.key === 'containers'" class="flex gap-2">
          <div v-for="container in row.collectedContainers.collection" :key="container?.id" class="flex gap-2">
            <Tag
              :tag="
                container?.container?.name
                  ? container.container.name
                  : `${t('global.bin')} ${container?.quantity} L`
              "
            />
          </div>
          <div v-if="row.collectedContainers.collection.length === 0">
            <p class="text-gray-400 italic">
              {{ t("global.no_container_for_the_moment") }}
            </p>
          </div>
        </div>

        <div v-if="col.key === 'material'">
          <div v-if="row.collectedContainers.collection.length > 0" class="flex gap-2">
            <div v-for="container in row.collectedContainers.collection" :key="container?.id">
              <Tag :tag="container?.material?.name" />
            </div>
          </div>
          <div v-else>
            <p class="text-gray-400 italic">
              {{ t("global.no_material_for_the_moment") }}
            </p>
          </div>
        </div>
      </template>

      <!-- <template #table-actions>
        <div class="flex gap-2 items-center">
          <button class="btn-primary" @click="goToService('new')">
            <div i="ph-plus-circle-duotone" class="mr-1" />
            {{ $t("global.add") }}
          </button>
        </div>
      </template> -->
      <template #row-actions="{ row }">
        <div class="flex flex-row justify-end gap-2 p-2">
          <button class="btn-secondary" @click="editService(row.id)">
            <PencilIcon :size="18" />
          </button>
          <!-- <button class="btn-cancel" @click="deleteService(row.id)">
            <div i="ph-trash" />
          </button> -->
        </div>
      </template>
      <template #footer>
        <OPagination v-model="page" :number-page="Math.ceil(collectsCount / 10)" />
      </template>
    </OTable>
  </section>
</template>
