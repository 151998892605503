import { gql } from '@apollo/client/core'

export const COLLECT_CSV_EXTRACT = gql`
  query CollectCSVExtract($input: CollectCSVExtractInput!) {
    collectCSVExtract(input: $input) {
      B64Csv
    }
  }
`

export const GENERATE_WASTE_REGISTER = gql`
query GenerateWasteRegister($input: GenerateWasteRegisterInput!) {
  generateWasteRegister(input: $input) {
    content
    name
  }
}
`

export const GENERRATE_SIGNED_UPLOAD_URL = gql`
mutation GenerateSignedUploadUrl($input: GenerateSignedUploadUrlInput!) {
  generateSignedUploadUrl(input: $input) {
    url
  }
}
`

export const STORE_DOCUMENT_REFERENCE = gql`
mutation StoreDocumentReference($input: StoreDocumentReferenceInput!) {
  storeDocumentReference(input: $input) {
    client {
      id
      contactLastName
      contactFirstName
      name
    }
    extension
    name
    type
    signedUrl
    id
  }
}
`

export const DELETE_STORED_DOCUMENT = gql`
mutation DeleteStoredDocument($input: StoredDocumentFilters!) {
  deleteStoredDocument(input: $input)
}
`
