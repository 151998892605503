<script setup lang="ts">
import { Calendar, MessageSquareMore } from 'lucide-vue-next'
import { serviceCreationStore } from '~/stores/serviceCreation'
import type { CollectGraphql } from '~/types/graphql-backend-types/gql-types'

const props = defineProps({
  isServices: {
    type: Boolean,
    required: false,
    default: true,
  },
  collects: {
    type: Array as PropType<CollectGraphql[]>,
    default: () => [],
    required: true,
  },
  collectsCount: {
    type: Number,
    default: 0,
    required: true,
  },
})

const { filters, page, collectsLoading } = storeToRefs(useCollectsStore())
const { loadServices } = useCollectsStore()

const router = useRouter()
const { t } = useI18n()

// const wastexPNG = '/assets/illustrations/truck-wastex.png'

const { collects, isServices } = toRefs(props)

function newService() {
  serviceCreationStore().cleanObject()
  serviceCreationStore().setisFinishCreating(true)
  router.push(`/waste-collection/new`)
}

onMounted(async () => {
  await loadServices()
})
</script>

<template>
  <div v-if="!collectsLoading" class="flex flex-col gap-4 p-4">
    <div v-if="collects" class="flex items-center justify-between gap-2">
      <div class="flex gap-1">
        <RecollectionSortingPopOver v-model="filters.sort" />
        <RecollectionStatusPopOver v-model="filters.statuses" />
      </div>
      <button class="hidden flex-row items-center gap-2 btn-primary md:flex" @click="newService()">
        <Calendar :size="18" />
        <p>{{ t("global.new_service") }}</p>
      </button>
    </div>
    <div v-if="collects?.length > 0" class="flex flex-col gap-4">
      <template v-for="collect in collects" :key="collect.id">
        <RecollectionCard :collect="collect" />
      </template>
    </div>
    <template v-else-if="isServices">
      <div class="flex flex-col items-start gap-4 border border-[#71E4BA] rounded bg-[#ECFDF6] p-6 md:flex-row">
        <img src="/assets/illustrations/truck-wastex.png" class="h-20 w-25">
        <div class="h-full flex flex-col items-start justify-center gap-3">
          <p class="text-xl text-[#374151] font-semibold">
            {{ t("global.no_services_for_the_moment") }}
          </p>
          <button class="w-fit btn-primary" @click="newService()">
            <Calendar :size="18" />
            <p>{{ t("global.schedule_a_service") }}</p>
          </button>
        </div>
      </div>
      <div
        class="flex flex-col items-start gap-4 border border-[#B1B3FF] rounded bg-[#F2F2FF] p-4 md:flex-row md:items-center md:justify-between md:gap-22"
      >
        <div class="flex items-center gap-2">
          <MessageSquareMore :size="18" class="#5D53FF" />
          <p class="text-lg">
            {{ t("global.problem_or_difficulty_contact_us") }}
          </p>
        </div>
        <a href="https://calendly.com/laure-wastextech " target="_blank" class="btn-secondary">
          <p>{{ t("global.contact_us") }}</p>
        </a>
      </div>
    </template>
    <OPagination v-model="page" :number-page="Math.ceil(collectsCount / 10)" />
  </div>
  <div v-else class="flex grow items-center justify-center">
    <Loader class="mb-2 h-8 w-8 text-gray-500" />
  </div>
</template>
