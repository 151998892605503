<script setup lang="ts">
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'

/**
 * @open : Boolean
 * (The state of the drawer, works in pair with the @close event)
 */

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  maxWidth: {
    type: String,
    required: false,
    default: 'max-w-[620px]',
  },
})

const emit = defineEmits<{
  (e: 'close'): void
}>()

function closeModal() {
  emit('close')
}
</script>

<template>
  <Teleport to="body">
    <TransitionRoot
      as="modalTemplate"
      :show="props.open"
    >
      <Dialog
        as="modalDiv"
        class="fixed inset-0 z-[9999]"
        @close="closeModal()"
      >
        <div className="fixed inset-0 overflow-y-auto">
          <div class="min-h-full flex items-center justify-center p-4 text-center sm:items-end sm:items-center sm:p-0">
            <TransitionChild
              as="modalTemplate"
              enter="ease-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in duration-200"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>
            <!-- This element is to trick the browser into centering the modal contents. -->
            <span
              class="hidden align-middle sm:inline-block sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <TransitionChild
              as="modalTemplate"
              enter="ease-out duration-300"
              enter-from="opacity-0 sm:scale-95"
              enter-to="opacity-100 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 sm:scale-100"
              leave-to="opacity-0 sm:scale-95"
            >
              <div
                class="inline-block w-[80vw] transform rounded-lg bg-white p-6 text-left shadow-xl transition-all"
                :class="props.maxWidth"
              >
                <slot name="content" />
                <div class="mt-6 flex">
                  <slot name="footer" />
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </Teleport>
</template>
