<script setup lang="ts">
import { userStore } from '~/stores/user'

const props = defineProps({
  title: {
    type: String,
    required: false,
  },
})

const route = useRoute()

const isProducer = computed(() => userStore().isProducer)
const isRecycler = computed(() => userStore().isRecycler)
</script>

<template>
  <header class="relative w-full flex items-center justify-between bg-white p-6">
    <slot>
      <img src="/assets/wastex-logo.png" alt="Wastex logo" class="h-6 w-18">

      <p class="absolute left-1/2 top-1/2 w-fit text-xl font-semibold -translate-x-50% -translate-y-50%">
        {{ title }}
      </p>

      <span v-if="isProducer" class="text-xs italic"> {{ $t("global.producer") }}</span>
      <span v-else-if="isRecycler" class="text-xs italic"> {{ $t("global.recycler") }}</span>
    </slot>
    <div
      class="absolute right-4 w-full flex items-end justify-between"
      :class="route.path === '' ? '-bottom-4' : 'bottom-0'"
    >
      <span class="items-end text-xs italic" />
      <!-- <span class="text-xs italic items-end">{{ VERSION }}</span> -->
    </div>
  </header>
</template>
