<script setup lang="ts">
/**
 * @modelValue : Number
 * (The selected Tab)
 *
 * @listTabs : Array<String>
 * (This list will render the defined tabs, don't forget to add localisation)
 *
 * @tabName : String
 * (The name of the query param to use for the tab)
 */

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
    default: 1,
  },
  listTabs: {
    type: Array<string>,
    required: true,
    default: [] as any[],
  },
  tabName: {
    type: String,
    required: false,
    default: 'tab',
  },
  disabledTabs: {
    type: Array<number>,
    required: false,
    default: [] as any[],
  },
})

const emit = defineEmits<{
  (e: 'update:modelValue', val: number): void
  (e: 'click', val: number): void
}>()

const route = useRoute()
const router = useRouter()

function setCurrentTab(index: number) {
  emit('click', index)
  if (props.disabledTabs.includes(index))
    return

  const routeValue = router?.currentRoute?.value
  const newRoute: any = {
    path: routeValue.path,
    query: { ...routeValue.query, [props.tabName]: index },
  }

  router.replace(newRoute)
  emit('update:modelValue', index)
}

function init() {
  const queries = route.query
  emit('update:modelValue', queries[props.tabName] ? Number.parseInt(queries[props.tabName] as string) : 0)
}

onMounted(init)
</script>

<template>
  <nav class="flex gap-6 overflow-x-auto">
    <template v-for="(tab, index) in props.listTabs" :key="index">
      <button
        class="group cursor-pointer whitespace-nowrap pb-2"
        :class="[
          props.modelValue === index
            ? 'text-secondary font-medium border-b-2 border-[#1BA774]'
            : 'border-transparent text-gray-500 hover:text-[#1BA774] border-b-2 hover:border-gray-300',
          props.disabledTabs.includes(index) ? '!text-gray-200 cursor-not-allowed !hover:border-none' : '',
        ]"
        @click="setCurrentTab(index)"
      >
        <slot :name="index" :tab="tab">
          {{ tab }}
        </slot>
      </button>
    </template>
  </nav>
</template>
