import { defineStore } from 'pinia'

export const adminTokenStore = defineStore({
  id: 'adminToken',
  state: () => ({
    overlayedUserToken: '',
    isConnectedAsUser: false,
    adminId: '',
  }),

  getters: {
    getOverlayedUserToken(): string {
      return this.overlayedUserToken
    },
  },
  actions: {
    setAdminId(uid: string): void {
      this.adminId = uid
    },
  },
})
