import { provideApolloClient } from '@vue/apollo-composable'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { defineStore } from 'pinia'
import {
  CLIENTS_QUERY_DELETE,
  CLIENTS_QUERY_INSERT,
  CLIENTS_QUERY_LIST,
  CLIENTS_QUERY_UPDATE,
  DELETE_WASTE_RECYCLER,
  DELETE_WR_WP,
  GET_OVERLAYED_CLIENT_TOKEN,
  INSERT_WR_WP,
  RECYCLERS_QUERY_GET_WITH_SITES,
} from '~/queries/clients'
import { userStore } from '~/stores/user'
import type { User } from '~/types/user'
import apolloClient from '../composables/graphql'
import apolloClientNest from '../composables/graphql-nest'
import { adminTokenStore } from './adminToken'

provideApolloClient(apolloClient)

export const orgStore = defineStore({
  id: 'org',
  state: () => ({
    org: {
      clients: [] as Array<any>,
      selected: 0,
    },
    overlayedClientToken: '',
    overlayedUserId: '',
    clientTokenRefreshKey: 0,
  }),
  getters: {
    getOrgs(): any {
      return this.org
    },
    getSelectedOrg(): any {
      return this.org.selected
    },
    getSelectedOrgName(): any {
      return this.org.clients.find(item => item.id === this.org.selected)?.name
    },
    getOrgsAsOptions(): any {
      if (this.org.clients) {
        return [
          { label: 'All Clients', value: 0 },
          ...this.org?.clients?.map((item) => {
            return { label: item.name, value: item.id, id: item.id, client_type: item.client_type }
          }),
        ]
      }
      return [{
        label: 'All Clients',
        value: 0,
      }, []]
    },
  },

  actions: {
    setSelectedOrg(org: any): any {
      this.org.selected = org
    },

    getOrg(orgId: string): any {
      return this.org.clients.find(item => item.id === orgId)
    },

    getProducerOrgs(): any[] {
      return this.org.clients.filter(item => item.client_type === 'producer')
    },

    getRecyclerOrgs(): any[] {
      return this.org.clients.filter(item => item.client_type === 'recycler')
    },
    getOverlayedClientToken() {
      return this.overlayedClientToken
    },
    async loadOrg(): Promise<any> {
      const user: any = userStore().user
      if (user) {
        const client_list = await apolloClient.query({ query: CLIENTS_QUERY_LIST })
        this.org.clients = client_list?.data?.clients
        if (this.org.selected === undefined)
          this.org.selected = this.org.clients[0]
      }
      return this.org
    },
    async setOverlayedClientToken(): Promise<any> {
      const token = await apolloClientNest.mutate({
        mutation: GET_OVERLAYED_CLIENT_TOKEN,
        variables: { input: { userId: this.overlayedUserId } },
      })

      signInWithCustomToken(getAuth(), token.data.logAs.userJWT).then((userCredential) => {
        adminTokenStore().overlayedUserToken = userCredential.user.accessToken
        adminTokenStore().isConnectedAsUser = !adminTokenStore().isConnectedAsUser
      })
      return this.overlayedClientToken
    },
    async connectUserAutomatically(token: string): Promise<any> {
      signInWithCustomToken(getAuth(), token)
    },
    loadOrgById(orgId: string): any {
      const user: any = userStore().user
      if (!user)
        return

      return this.org.clients.find(item => item.id == orgId)
    },
    async updateOrg(org: any, clients_data: any): Promise<any> {
      const user: User = userStore().user
      if (!user)
        return
      delete org.__typename

      const updatedOrg = await apolloClient.mutate({
        mutation: CLIENTS_QUERY_UPDATE,
        variables: { id: org.id, object: org, clients_data },
      })

      this.org.clients = [
        ...this.org.clients.filter(item => item.id !== org.id),
        updatedOrg.data.update_clients_by_pk,
      ]
    },

    async createOrg(org: any, wr_wp: any, isRecycler: boolean): Promise<any> {
      const client = await apolloClient.mutate({ mutation: CLIENTS_QUERY_INSERT, variables: { object: org } })

      const dataToSend = wr_wp.map((item: any) => ({
        wr_id: isRecycler ? client.data.insert_clients_one.id : item.wr_id,
        wp_id: isRecycler ? item.wp_id : client.data.insert_clients_one.id,
      }))

      const relationWPWR = await apolloClient.mutate({
        mutation: INSERT_WR_WP,
        variables: { objects: dataToSend },
      })

      this.org.clients = [...this.org.clients, client.data.insert_clients_one]

      return { client, relationWPWR }
    },

    async createRelationWPWR(wr_wp: any): Promise<any> {
      await apolloClient.mutate({
        mutation: INSERT_WR_WP,
        variables: { objects: wr_wp },
      })
      this.loadOrg()
    },

    async deleteOrgRelationWPWR(wr_wp_id: any): Promise<any> {
      await apolloClient.mutate({
        mutation: DELETE_WR_WP,
        variables: { ids: wr_wp_id },
      })
      this.loadOrg()
    },

    async deleteOrg(orgId: string): Promise<any> {
      const user: User = userStore().user
      if (!user)
        return

      await apolloClient.mutate({ mutation: CLIENTS_QUERY_DELETE, variables: { id: orgId } })
      // todo: remove from list
    },
    async getRecyclerWithSite(): Promise<any> {
      const user: any = userStore().user
      if (!user)
        return
      const recyclerWithSite = await apolloClient.query({ query: RECYCLERS_QUERY_GET_WITH_SITES })
      return recyclerWithSite.data.sites
    },

    async deleteWasteRecycler(wp_id: number, wr_id: number) {
      const user: any = userStore().user
      if (!user)
        return

      await apolloClient.mutate({
        mutation: DELETE_WASTE_RECYCLER,
        variables: { _eq: wr_id },
      })
    },
  },
})
