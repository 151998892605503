export function camelToSnakeCase(str: string): string {
  return str.split('').map((char, index) => {
    if (index === 0) {
      return char.toLowerCase();
    }
    if (char === char.toUpperCase()) {
      return `_${char.toLowerCase()}`;
    }
    return char;
  }).join('');
}