export function sanitizeObject<T>(obj: T, additionalPropertiesToRemove: Array<keyof T>): Partial<T> {
  const commonProperties = ['created_at', 'updated_at', 'deleted_at', '__typename']
  const propertiesToRemove = [...commonProperties, ...additionalPropertiesToRemove] as Array<keyof T>

  const sanitizedObject = JSON.parse(JSON.stringify(obj)) as Partial<T>

  propertiesToRemove.forEach((property) => {
    delete sanitizedObject[property]
  })

  return sanitizedObject
}
