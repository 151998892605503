import { defineStore } from 'pinia'
import { provideApolloClient } from '@vue/apollo-composable'
import _, { isEqual } from 'lodash'
import apolloClient from '../composables/graphql'
import { apiStore } from './api'
import { useDocumentStore } from './document'
import type { CollectGraphql, CollectedContainerGraphql, Maybe } from '~/types/graphql-backend-types/gql-types'
import { DocumentContext, DocumentType } from '~/types/graphql-backend-types/gql-types'

provideApolloClient(apolloClient)

export const serviceCreationStore = defineStore({
  id: 'serviceCreation',
  state: () => ({
    isEditing: false,
    isFinishCreating: false,
    isFinishEditing: false,
    created: false,
    edited: false,
    loading_file: false,
    siteName: '',
    serviceCreation: [
      {
        site: '',
      },
      {
        raw_materials: '',
      },
      {
        containers: [],
      },
      {
        fill_rates: [],
        pictures: [],
      },
      {
        dates: {
          start: null,
          end: null,
        },
      },
      {
        notes: '',
        referent: '',
      },
    ] as Array<any>,
    id: {},
    step: 0,
    status: '',
    scope: {
      start: 0,
      end: 6,
    },
  }),

  getters: {
    getStep(): number {
      return this.step
    },
  },

  actions: {
    cleanObject() {
      this.created = false
      this.edited = false
      this.isEditing = false
      this.isFinishCreating = false
      this.isFinishEditing = false
      this.serviceCreation = [
        {
          site: '',
        },
        {
          raw_materials: '',
        },
        {
          containers: [],
        },
        {
          fill_rates: [],
          pictures: [],
        },
        {
          dates: {
            start: null,
            end: null,
          },
        },
        {
          referent: '',
          notes: '',
        },
      ]
      this.step = 0
    },
    getObject() {
      return this.serviceCreation
    },
    getPreviousStep(step: number): number {
      return step - 1
    },
    getNextStep(step: number): number {
      if (step === 4)
        return step
      return step + 1
    },
    setStep(step: number): void {
      if (this.scope.end < step && this.scope.end !== 6)
        return
      if (this.scope.start > step && this.scope.start !== 0)
        return
      this.step = step
    },
    getStepData(step: number): any {
      return this.serviceCreation[step]
    },
    setIsCreated(created: boolean): void {
      this.created = created
    },
    setIsEdited(edited: boolean): void {
      this.edited = edited
    },
    setIsEditing(isEditing: boolean): void {
      this.isEditing = isEditing
    },
    setisFinishCreating(isFinishCreating: boolean): void {
      this.isFinishCreating = isFinishCreating
    },
    setIsFinishEditing(isFinishEditing: boolean): void {
      this.isFinishEditing = isFinishEditing
    },
    setScope(start: number, end: number): void {
      this.scope.start = start
      this.scope.end = end
    },

    setStepData(step: number, data: any): void {
      const resetServiceCreationData = () => {
        if (step === 0) {
          this.serviceCreation[1] = ''
          this.serviceCreation[2] = []
          this.serviceCreation[3] = { fill_rates: [], pictures: [] }
        }
        else if (step === 1) {
          this.serviceCreation[2] = []
          this.serviceCreation[3] = { fill_rates: [], pictures: [] }
        }
        else if (step === 2) {
          console.log('Step 2')
        }
      }
      if (isEqual(data, this.serviceCreation[step]))
        return

      resetServiceCreationData()

      if (step === 2) {
        const currentLength = this.serviceCreation[2].length
        const newLength = data.length

        if (newLength > currentLength) {
          const additionalItems = newLength - currentLength
          this.serviceCreation[3].fill_rates.push(...new Array(additionalItems).fill(0))
          this.serviceCreation[3].pictures.push(...new Array(additionalItems).fill([]))
        }
        else if (newLength < currentLength) {
          this.serviceCreation[3].fill_rates.length = newLength
        }
        else {
          this.serviceCreation[3].fill_rates = new Array(newLength).fill(0)
        }
      }
      this.serviceCreation[step] = data
    },
    async findRecyclerId(): Promise<any> {
      // const material = this.serviceCreation[1];
      // const org = await orgStore().loadOrgByMaterialId(material);
      // const org = orgStore().getOrgs();
      // console.log(org);
      // const result = {
      //   wr_client_id: "",
      //   wp_site_id: "",
      // };
      // if (org.waste_recycler.length > 0) {
      //   result.wr_client_id = org.data.raw_material_wr[0].site.client.id;
      //   result.wp_site_id = org.id;
      // }
      // return result;
    },
    async createService(): Promise<void> {
      const { uploadDocument, persistDocument } = useDocumentStore()
      const { createServiceProducer, updateServiceWithContainers } = useCollectsStore()
      this.scope.start = 0
      this.scope.end = 6

      const containersPicturesUploadPromises = this.serviceCreation[3].pictures.map((containerPicturesObject: any) => {
        const containerPicturesArray = Object.values(containerPicturesObject)
        return Promise.all(
          containerPicturesArray.map(async (picture: any) => {
            const file = new File([picture.file], picture.name, {
              type: picture.file.type,
            })
            return await uploadDocument({ file })
          }),
        )
      })

      await Promise.all(containersPicturesUploadPromises);

      const wr = await this.findRecyclerId()

      const data = {
        data: this.serviceCreation[5],
        start_datetime: this.serviceCreation[4].start_date,
        end_datetime: this.serviceCreation[4].end_date,
        status: 'request_sent',
        type_id: 'bin_collection',
        wp_site_id: this.serviceCreation[0],
        wr_client_id: wr?.wr_client_id,
        wr_site_id: wr?.wp_site_id,
        waste_service_raw_materials_containers: {
          data: this.serviceCreation[2].map((container: any, index: any) => {
            return {
              raw_materials_container_id: container,
              fill_rate: this.serviceCreation[3].fill_rates[index],
              // attachments: filteredContainersPictures[index],
            }
          }),
        },
      }

      if (data.wr_client_id === '') {
        delete data.wr_client_id
        delete data.wr_site_id
      }

      if (!this.isEditing) {
        const { insert_waste_service_one } = await createServiceProducer(data)
        const collectContainerId = insert_waste_service_one.waste_service_raw_materials_containers[0].id

        // await useCollectsStore().addService(service)
        for (const pictures of this.serviceCreation[3].pictures) {
          const containerPicturesArray = Object.values(pictures)
          for (const picture of containerPicturesArray) {
            await persistDocument({ file: picture.file, context: { id: collectContainerId, type: DocumentContext.CollectedContainer }, type: DocumentType.Picture })
          }
        }

        this.isFinishCreating = true
      }
      else {
        data.id = this.id
        data.status = this.status
        await updateServiceWithContainers(data)
        this.isEditing = false
        this.isFinishEditing = true
      }
    },
    getSiteName(): string | undefined {
      return this.siteName
    },
    setSiteName(siteName: string): void {
      this.siteName = siteName
    },
    setServiceForEdit(service: CollectGraphql): void {
      this.isEditing = true
      this.serviceCreation[0] = service.client.sites.collection[0]?.id
      this.serviceCreation[1]
        = service.collectedContainers.collection[0]?.material.id
      this.serviceCreation[2] = service.collectedContainers.collection.map((container: Maybe<CollectedContainerGraphql>) => {
        return container?.material.id
      })

      this.serviceCreation[3] = {
        fill_rates: service.collectedContainers.collection.map((container: Maybe<CollectedContainerGraphql>) => {
          return container?.fillRate
        }),
        pictures: service.collectedContainers.collection?.map((container: Maybe<CollectedContainerGraphql>) => {
          if (!container?.attachments)
            return []
          return container.attachments.map((attachment: any) => {
            return {
              file: attachment,
            }
          })
        }),
      }
      this.serviceCreation[4] = {
        start_date: service.startsAt,
        end_date: service.endsAt,
      }
      this.serviceCreation[5] = service
      this.id = service.id
      this.status = service.status
    },
  },
})
