<script lang="ts" setup>
interface GridColumn {
  key: string
  label: string
  width: number
  labelClass?: string
  class?: string
}

/**
 * @columns : GridColumn[]
 * (define the columns parameters :
 *   key => key of the objects in rows that will display
 *   label => the label that will display in the thead
 *   width => optional, if defined : the width of the column (in fr)
 *   class => optional, if defined : the class of the column (ex: text-center)
 *   labelClass => optional, if defined : the class of the label (ex: text-center)
 * )
 *
 * @rows : Object[]
 * (Any objects. As soon as a key is matching the :columns prop, it wil render to the user)
 *
 */

const props = defineProps({
  columns: {
    type: Array as PropType<GridColumn[]>,
    required: true,
  },
  rows: {
    type: Array,
    required: true,
  },
  headerClass: {
    type: String,
    required: false,
  },
})
/**
 *  @click : Function
 * (emit the row clicked)
 */

const emit = defineEmits<{
  (e: 'click', val: any): void
}>()
const { t } = useI18n()
const gridTotalWidth = computed(() => {
  return props.columns.reduce((acc, column) => {
    return acc + (column.width || 1)
  }, 0)
})

function rowClick(row: any) {
  emit('click', row)
}
</script>

<template>
  <div class="overflow-auto">
    <section
      class="mx-auto min-h-full w-full flex flex-col gap-2 rounded-md"
      :style="{ minWidth: `${gridTotalWidth * 48}px` }"
    >
      <div
        :class="`grid grid-flow-col w-full py-2 px-4 gap-2 text-sm text-[#8C8C8C] ${props.headerClass}`"
        :style="{ gridTemplateColumns: `repeat(${gridTotalWidth}, minmax(0, 1fr))` }"
      >
        <template v-for="column in columns" :key="column">
          <div class="flex items-center" :style="{ gridColumn: `span ${column.width} / span ${column.width}` }">
            <slot :key="column.key" :name="`${column.key}-label`" :column-label="column.label" :label="column.label">
              <p :class="column.labelClass">
                {{ t(column.label) }}
              </p>
            </slot>
          </div>
        </template>
      </div>
      <template v-for="row in props.rows" :key="row">
        <div
          class="grid grid-flow-col w-full cursor-pointer justify-between gap-2 bg-white px-4 py-2 text-sm text-[#262626]"
          :style="{ gridTemplateColumns: `repeat(${gridTotalWidth}, minmax(0, 1fr))` }"
          @click.prevent="rowClick(row)"
        >
          <template v-for="column in columns" :key="column">
            <div
              :class="`flex items-center ${column.class}`"
              :style="{ gridColumn: `span ${column.width} / span ${column.width}` }"
            >
              <slot :key="column.key" :name="column.key" :row="row" :value="row[column.key]">
                {{ row[column.key] }}
              </slot>
            </div>
          </template>
        </div>
      </template>
    </section>
  </div>
</template>
