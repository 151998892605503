import { defineStore } from 'pinia'
import apolloClientNest from '../composables/graphql-nest'
import { GET_TREATMENT_CODE, GET_WASTE_CODE_EU } from '~/queries/code-validation'

export const codeValidationStore = defineStore({
  id: 'codeValidation',
  state: () => ({
    treamentCodes: [],
    wasteCodes: [],
  }),
  getters: {
    getTreatmentCodes(): string[] {
      return this.treamentCodes
    },
    getWasteCodes(): string[] {
      return this.wasteCodes
    },
  },
  actions: {
    async loadTreatmentCodes(): Promise<string[]> {
      const treatmentCodes = await apolloClientNest.query({
        query: GET_TREATMENT_CODE,
      })
      this.treamentCodes = treatmentCodes.data.treatmentCodes
      return treatmentCodes.data.treatmentCodes
    },

    async loadWasteCodes(): Promise<string[]> {
      const wasteCodes = await apolloClientNest.query({
        query: GET_WASTE_CODE_EU,
      })
      this.wasteCodes = wasteCodes.data.wasteCodes
      return wasteCodes.data.wasteCodes
    },
    normalizeCode(code: string) {
      return code.replace(/\s+/g, '').replace(/\*/g, '')
    },
    searchCodes(codes: any[], normalizedInput: string) {
      for (const item of codes) {
        if (this.normalizeCode(item.code) === normalizedInput) {
          return true
        }
      }
      return false
    },
    validateUserInput(input: string): boolean {
      const normalizedInput = this.normalizeCode(input)

      for (const category of this.wasteCodes) {
        for (const subCategory of category.subCategories) {
          if (this.searchCodes(subCategory.codes, normalizedInput)) {
            return true
          }
        }
      }
      return false
    },
  },
})
