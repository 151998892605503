<script setup lang="ts">
import type { PropType } from 'vue'
import { CalendarRange, Eye, TriangleAlert } from 'lucide-vue-next'
import type { IncidentGraphql } from '~/types/graphql-backend-types/gql-types'

const props = defineProps({
  incident: {
    type: Object as PropType<IncidentGraphql>,
    required: true,
  },
})

defineEmits<{
  click: [value: object]
}>()

const { incident } = toRefs(props)
const isExpanded = ref<boolean>(false)
const isFileReaderModalOpen = ref<boolean>(false)
const fileToView = ref<any>(null)
function formatText(text: string) {
  if (!text)
    return ''
  text = text.charAt(0).toUpperCase() + text.slice(1)
  return text.replace(/_/g, ' ')
}

function toggleExpandCard() {
  isExpanded.value = !isExpanded.value
}

function openModal(file: any, name?: string, type?: string) {
  isFileReaderModalOpen.value = true
  fileToView.value = file
}
</script>

<template>
  <div
    class="flex flex-row cursor-pointer items-start gap-4 border border-neutral-100 rounded-lg bg-white p-4 lg:justify-between"
    @click.stop.prevent="toggleExpandCard"
  >
    <div class="rounded-lg bg-[#FFF1F0] p-2.5">
      <TriangleAlert color="#CF1322" :size="24" />
    </div>
    <div class="w-full flex flex-col gap-2">
      <p class="font-semibold">
        {{ $t("global.incident_received") }} / {{ formatText(incident.type) }}
      </p>
      <div class="flex flex-row items-center gap-4 text-[#8C8C8C]">
        <!-- <span>{{ incident.type_id }} </span> -->
        <span class="flex flex-row items-center gap-1 text-sm text-[#8C8C8C]">
          <CalendarRange :size="18" />
          {{
            new Date(incident.createdAt).toLocaleDateString([], {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          }}
        </span>
      </div>
      <div class="flex items-start justify-between">
        <p
          class="line-clamp-2 text-sm text-[#8C8C8C]"
          :class="{ 'line-clamp-2': !isExpanded, 'line-clamp-none': isExpanded }"
        >
          {{ incident.comment }}
        </p>
      </div>
      <div v-if="incident.documents" class="flex items-center gap-2">
        <div v-for="picture in incident.documents.collection" :key="picture?.id">
          <img :src="picture?.signedUrl" class="mt-4 h-10 w-10 rounded-lg object-cover" @click.stop.prevent="openModal(picture)">
        </div>
      </div>
      <div class="w-full flex items-center justify-between gap-2">
        <Tag
          v-if="incident.penaltyAmount"
          :tag="`Pénalité ${incident.penaltyAmount} €`"
          class="bg-[#FFF1F0] text-[#A8071A]"
        />
        <div v-else />
        <!-- <p  class="text-sm">{{ incident.penalty_comment }}</p> -->
        <!-- <button @click="goToService()" class="btn-secondary h-8">
          <Eye :size="18" />
          {{ $t("global.see_service") }}
        </button> -->
      </div>
    </div>
    <FileReaderModal :file="fileToView" :opened="isFileReaderModalOpen" @close="isFileReaderModalOpen = false" />
  </div>
</template>
