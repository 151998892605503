<script setup lang="ts">
import { X } from 'lucide-vue-next'

const props = defineProps({
  file: {
    type: Object,
  },
  opened: {
    type: Boolean,
  },
})

const emit = defineEmits<{
  (e: 'close'): void
}>()

const { opened, file } = toRefs(props)
</script>

<template>
  <Teleport to="body">
    <OModal :open="opened">
      <template #content>
        <div
          v-show="file"
          class="w-full flex flex-col items-start gap-4"
        >
          <div class="w-full flex items-center justify-between">
            <h1 class="text-xl font-semibold">
              {{ $t("global.pictures") }}
            </h1>
            <button class="border border-transparent rounded p-1 transition hover:border-[#E5E7EB]" @click="emit('close')">
              <X :size="16" color="#71717A" />
            </button>
          </div>
          <div class="mx-auto">
            <FileReader
              v-if="opened"
              :file-name="file?.name"
              :file-type="file?.type"
              :file-src="file?.signedUrl"
            />
          </div>
        </div>
      </template>
    </OModal>
  </Teleport>
</template>
