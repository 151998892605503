<script setup lang="ts">
import { ChevronLeft, ChevronRight } from 'lucide-vue-next'

/**
 * @modelValue : Number
 * (The current page)
 *
 * @numberPage : Boolean
 * (The number of pages to display)
 */

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
    default: 1,
  },
  numberPage: {
    type: Number,
    required: true,
    default: 1,
  },
})

const emit = defineEmits<{
  (e: 'update:modelValue', val: number): void
}>()

function goToPagination(page: number | string) {
  if (page === '...')
    return

  if (page !== props.modelValue)
    emit('update:modelValue', page)
}

const paginationList = computed(() => {
  const pagination = []
  const numberPage = props.numberPage
  const modelValue = props.modelValue
  if (numberPage <= 7) {
    for (let i = 1; i <= numberPage; i++)
      pagination.push(i)
  }
  else {
    if (modelValue <= 4) {
      for (let i = 1; i <= 5; i++)
        pagination.push(i)

      pagination.push('...')
      pagination.push(numberPage)
    }
    else if (modelValue >= numberPage - 3) {
      pagination.push(1)
      pagination.push('...')
      for (let i = numberPage - 4; i <= numberPage; i++)
        pagination.push(i)
    }
    else {
      pagination.push(1)
      pagination.push('...')
      for (let i = modelValue - 1; i <= modelValue + 1; i++)
        pagination.push(i)

      pagination.push('...')
      pagination.push(numberPage)
    }
  }
  return pagination
})

function updatePagination(action: string) {
  switch (action) {
    case 'previous':
      if (props.modelValue !== 1)
        emit('update:modelValue', props.modelValue - 1)
      break
    case 'next':
      if (props.modelValue !== props.numberPage)
        emit('update:modelValue', props.modelValue + 1)
      break
    default:
      break
  }
}
</script>

<template>
  <nav :aria-label="$t('global.table.nav')">
    <ul class="flex items-center justify-center gap-4">
      <li>
        <button
          class="h-8 w-8 flex items-center justify-center border border-[#E6E6E5] rounded-full"
          :class="props.modelValue === 1 ? 'text-[#C0C0BF] cursor-default' : 'text-[#404040] hover:border-[#1BA774] transition'"
          @click="updatePagination('previous')"
        >
          <ChevronLeft :size="16" />
        </button>
      </li>
      <template v-for="index in paginationList" :key="index">
        <li>
          <button
            class="h-6 w-6 flex cursor-pointer items-center justify-center rounded-full text-sm"
            :class="props.modelValue === index ? 'text-white bg-primary' : 'text-[#676765] border border-transparent hover:border-[#1BA774] transition'"
            @click="goToPagination(index)"
          >
            {{ index }}
          </button>
        </li>
      </template>
      <li>
        <button
          class="h-8 w-8 flex items-center justify-center border border-[#E6E6E5] rounded-full"
          :class="props.modelValue === props.numberPage ? 'text-[#C0C0BF] cursor-default' : 'text-[#404040] hover:border-[#1BA774] transition'"
          @click="updatePagination('next')"
        >
          <ChevronRight :size="16" />
        </button>
      </li>
    </ul>
  </nav>
</template>
