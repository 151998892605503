import { defineStore } from 'pinia'
import { provideApolloClient } from '@vue/apollo-composable'
import _ from 'lodash'
import apolloClient from '../composables/graphql'
import { userStore } from '~/stores/user'
import type { User } from '~/types/user'
import { sanitizeObject } from '~/utils/manipulateObject'
import {
  INSERT_RAW_MATERIALS_WR,
  RAW_MATERIALS_BY_SITE_QUERY,
  RAW_MATERIALS_QUERY_DELETE,
  RAW_MATERIALS_QUERY_INSERT,
  RAW_MATERIALS_QUERY_LIST,
  RAW_MATERIALS_QUERY_UPDATE,
} from '~/queries/raw-materials'

provideApolloClient(apolloClient)

export const rawMaterialsStore = defineStore({
  id: 'rawMaterials',
  state: () => ({
    rawMaterials: {
      list: [] as Array<any>,
    },
  }),
  getters: {
    getRawMaterials(): any[] {
      return this.rawMaterials.list
    },
    getRawMaterialsAsOptions(): any[] {
      const options = this.rawMaterials.list.map((item) => {
        return {
          label: item.name,
          value: item.id,
        }
      })
      return options
    },
  },
  actions: {
    getRawMaterial(rawMaterialId: string): any {
      return this.rawMaterials.list.find(item => item.id.toString() === rawMaterialId.toString())
    },

    async loadRawMaterials(): Promise<void> {
      const user: any = userStore().user
      if (!user)
        return

      const rawMaterials: { data: { raw_materials: any } } = await apolloClient.query({
        query: RAW_MATERIALS_QUERY_LIST,
      })

      this.rawMaterials.list = rawMaterials?.data?.raw_materials
    },

    getRawMaterialByClientId(clientId: string): any {
      const user: any = userStore().user
      if (!user)
        return
      return this.rawMaterials.list.filter(item => item.client_id == clientId)
    },

    // TODO : Replace with something without query
    async getMaterialBySiteId(siteId: string): Promise<any> {
      const user: any = userStore().user
      if (!user)
        return

      const rawMaterials: { data: { raw_materials: any } } = await apolloClient.query({
        query: RAW_MATERIALS_BY_SITE_QUERY,
        variables: { site_id: siteId },
      })
      return rawMaterials
    },

    async loadRawMaterial(rawMaterialId: string): Promise<any> {
      const user: any = userStore().user
      if (!user)
        return

      return this.rawMaterials.list.filter(item => item.id == rawMaterialId)
    },
    async createRawMaterial(rawMaterial: any): Promise<any> {
      const user: User = userStore().user
      if (!user)
        return

      const newMaterial = await apolloClient.mutate({
        mutation: RAW_MATERIALS_QUERY_INSERT,
        variables: { object: rawMaterial },
      })

      this.rawMaterials.list = [...this.rawMaterials.list, newMaterial.data.insert_raw_materials_one]

      return newMaterial
    },

    async updateRawMaterial(rawMaterial: any): Promise<any> {
      const user: User = userStore().user
      if (!user)
        return

      const rawMaterialToUpdate = sanitizeObject(rawMaterial, ['id', 'raw_material_wrs'])

      const updatedRawMaterial = await apolloClient.mutate({
        mutation: RAW_MATERIALS_QUERY_UPDATE,
        variables: {
          id: rawMaterial.id,
          object: rawMaterialToUpdate,
          wrsData: rawMaterial.raw_material_wrs.data.recycler_id,
        },
      })
      updatedRawMaterial.data.update_raw_materials_by_pk.raw_material_wrs = updatedRawMaterial.data.update_raw_material_wr.returning

      if (updatedRawMaterial.data.update_raw_material_wr.affected_rows === 0) {
        const newWrRawMaterial = await apolloClient.mutate({
          mutation: INSERT_RAW_MATERIALS_WR,
          variables: {
            object: {
              recycler_id: rawMaterial.raw_material_wrs.data.recycler_id,
              raw_material_id: rawMaterial.id,
            },
          },
        })
        updatedRawMaterial.data.update_raw_materials_by_pk.raw_material_wrs = newWrRawMaterial.data.insert_raw_material_wr.returning
      }
      const index = _.findIndex(this.rawMaterials.list, { id: rawMaterial.id })
      if (index !== -1) {
        this.rawMaterials.list = [
          ...this.rawMaterials.list.slice(0, index),
          updatedRawMaterial.data.update_raw_materials_by_pk,
          ...this.rawMaterials.list.slice(index + 1),
        ]
      }
      return updatedRawMaterial
    },
    async deleteRawMaterial(rawMaterialId: string): Promise<any> {
      const user: User = userStore().user
      if (!user)
        return

      await apolloClient.mutate({
        mutation: RAW_MATERIALS_QUERY_DELETE,
        variables: { id: rawMaterialId },
      })

      this.rawMaterials.list = this.rawMaterials.list.filter(item => item.id !== rawMaterialId)
    },
  },
})
