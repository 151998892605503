<script setup lang="ts">
import type { IncidentGraphql } from '~/types/graphql-backend-types/gql-types'

const { t } = useI18n()
const { getIncidents } = storeToRefs(useCollectsStore())
const isLoading = ref(false)
const incidents = ref<IncidentGraphql[]>([])

async function loadData() {
  isLoading.value = true
  incidents.value = getIncidents.value
  isLoading.value = false
}

onMounted(() => {
  loadData()
})
</script>

<template>
  <div class="flex flex-col gap-4 p-4">
    <template v-if="!isLoading">
      <template v-if="incidents?.length">
        <IncidentCard v-for="incident in incidents" :key="incident.id" :incident="incident" />
      </template>
      <div v-else class="text-center text-sm text-gray-400 italic">
        {{ t("global.no_incidents_created_for_the_moment") }}
      </div>
    </template>
    <Loader v-else />
  </div>
  <FileReaderModal />
</template>
