import { gql } from '@apollo/client/core'

export const SITES_QUERY_LIST = gql`
  query Sites {
    sites {
      additional_information
      address
      client_id
      id
      name
      postal_code
      client {
        id
        name
      }
      raw_materials_containers {
        id
      }
    }
  }
`
export const SITES_QUERY_GET = gql`
  query Site($id: Int!) {
    sites_by_pk(id: $id) {
      additional_information
      address
      client_id
      id
      name
      postal_code
    }
  }
`

export const SITES_QUERY_UPDATE = gql`
  mutation UpdateSite($id: Int!, $object: sites_set_input!) {
    update_sites_by_pk(pk_columns: { id: $id }, _set: $object) {
      additional_information
      address
      client_id
      id
      name
      postal_code
    }
  }
`
export const SITES_QUERY_GET_BY_CLIENT = gql`
  query SitesByClient($client_id: Int!) {
    sites(where: { client_id: { _eq: $client_id } }) {
      additional_information
      address
      client_id
      id
      name
      postal_code
    }
  }
`

export const SITES_QUERY_INSERT = gql`
  mutation InsertSite($object: sites_insert_input!) {
    insert_sites_one(object: $object) {
      additional_information
      address
      client_id
      name
      postal_code
    }
  }
`

export const SITES_QUERY_LIST_WITH_RECYCLER = gql`
 query Sites {
  sites {
    additional_information
    address
    client_id
    id
    name
    postal_code
    client {
      id
      name
    }
    raw_materials_containers {
      id
      raw_material {
        raw_material_wrs {
          recycler {
            attachments
            client_type
            slug
            name
            id
          }
        }
      }
    }
  }
}
`
