import { createI18n } from 'vue-i18n'
import type { UserModule } from '~/types'

// Import i18n resources
// https://vitejs.dev/guide/features.html#glob-import
//
// Don't need this? Try vitesse-lite: https://github.com/antfu/vitesse-lite
export const messages = Object.fromEntries(
  Object.entries(import.meta.glob('../../../locales/*.json', { eager: true })).map(([key, value]) => {
    const json = key.endsWith('.json')
    return [key.slice(17, json ? -5 : -4), value?.default]
  }),
)

export const i18n = createI18n({
  legacy: false,
  locale: 'en',
  globalInjection: true,
  messages,
})

export const install: UserModule = ({ app }) => {
  app.use(i18n)
}
