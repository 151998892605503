import { defaultConfig, plugin } from '@formkit/vue'
// import { generateClasses } from '@formkit/tailwindcss'
// import { formkitTheme } from '~/themes/formkit-theme'

import { createAutoHeightTextareaPlugin } from '@formkit/addons'
import { de, fr } from '@formkit/i18n'
import { applicationIcons } from '@formkit/icons'
import { createProPlugin, inputs } from '@formkit/pro'
import { getEnv } from '~/config'
import { rootClasses } from '~/themes/formkit-theme'
import type { UserModule } from '~/types'
import { availableFormkitIcons } from '~/utils/icons'

const pro
  = getEnv() === 'production' ? createProPlugin('fk-6100abc1a1', inputs) : createProPlugin('fk-a423e3511e', inputs)

function getNavigatorLanguage() {
  let language = 'en'
  if (typeof window !== 'undefined') {
    language = navigator.language.substring(0, 2)
    if (language !== 'de' && language !== 'fr')
      language = 'en'
  }
  return language
}

// Configure FormKit:
const config = defaultConfig({
  config: {
    // classes: generateClasses(formkitTheme),
    rootClasses,
  },
  icons: {
    ...applicationIcons,
    ...availableFormkitIcons,
  },
  locales: { de, fr },
  locale: getNavigatorLanguage(),
  plugins: [
    pro,
    createAutoHeightTextareaPlugin(),
  ],
})

export const install: UserModule = ({ app }) => {
  app.use(plugin, config)
}
