import type { Unsubscribe } from 'firebase/auth'
import type { UserModule } from '~/types'
import { fb_auth } from '~/services/firebase'

let unsubscribe: Unsubscribe | null = null

export const install: UserModule = ({ isClient, router }) => {
  if (isClient) {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const query = Object.fromEntries(urlSearchParams.entries())
    unsubscribe && unsubscribe()
    router.beforeEach((to, from, next) => {
      if (
        to.matched.some((record: any) => {
          return record.meta.requiresAuth
        })
      ) {
        unsubscribe = fb_auth.onAuthStateChanged((user) => {
          if (user) {
            next()
          }
          else {
            next({
              path: '/auth/login',
              query,
            })
          }
        })
      }
      else {
        next()
      }
    })
  }
}
