import { gql } from '@apollo/client/core'

export const USERS_NEST_QUERY_LIST = gql`
query Collection($filters: UserFilterGraphql) {
  users(filters: $filters) {
    collection {
      role
      lastName
      id
      firstName
      email
      function
      disabled
      client {
        id
      }
    }
  }
}
`

export const USERS_QUERY_LIST = gql`
  query UsersQuery {
    users {
      client_id
      email
      first_name
      id
      last_name
      function
      picture_url
      site_ids
      role
      attachments
      disabled
      client {
        name
        id
        attachments
        client_type
        option_td_autosign_enabled
      }
    }
  }
`

export const USERS_QUERY_UPDATE = gql`
  mutation UpdateUser($id: String!, $_set: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $_set) {
      attachments
    }
  }
`

export const GET_CURRENT_USER = gql`
  query Client {
    me {
      client {
        id
        isConnectedToTrackdechets
        sites {
          collection {
            address
            id
            name
            zipCode
          }
          count
        }
      }
      user {
        email
        firstName
        id
        lastName
        role
      }
    }
  }
`

export const TOGGLE_TRACKDECHETS_AUTOSIGN = gql`
  mutation ToggleTrackdechetsAutosign {
    toggleTrackdechetsAutosign {
      autosignEnabledForTrackdechets
      id
      isConnectedToTrackdechets
      name
      sites {
        collection {
          address
          id
          name
          zipCode
        }
      }
      slug
    }
  }
`
