<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ChevronRight, EllipsisVertical, Pencil, X } from 'lucide-vue-next'
import { CollectStatus } from '~/types/graphql-backend-types/gql-types'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
})

const emit = defineEmits<{
  (e: 'cancelService'): void
  (e: 'editService'): void
}>()

const { t } = useI18n()

const canEdit = computed(() => {
  return (
    props.modelValue === CollectStatus.RequestSent || props.modelValue === CollectStatus.Confirmed || props.modelValue === CollectStatus.PickupConfirmed
  )
})

const canCancel = computed(() => {
  return props.modelValue === CollectStatus.RequestSent || props.modelValue === CollectStatus.WpCancel
})

function cancelService() {
  emit('cancelService')
}

function editService() {
  emit('editService')
}
</script>

<template>
  <Popover v-slot="{ open }" class="relative flex-shrink-0">
    <PopoverButton
      class="h-full gap-1.5 px-1 py-1 btn-secondary"
      :class="open ? '!border-[#373FEF]' : '!hover:text-secondary'"
    >
      <div v-if="props.label" class="">
        {{ props.label }}
      </div>
      <EllipsisVertical :size="16" />
    </PopoverButton>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <PopoverPanel class="absolute right-0 top-8 z-10 max-w-56 w-screen flex">
        <div
          class="min-w-50 flex-auto overflow-hidden rounded bg-white text-sm leading-2 ring-1 ring-gray-900/5"
          style="box-shadow: 0 2px 6px 2px rgba(60, 64, 67, 0.15)"
          @click.stop.prevent
        >
          <!-- Expandable pop over example -->

          <div class="flex flex-col py-2">
            <div class="group bg-white">
              <button
                class="w-full flex cursor-pointer items-center gap-2 px-3 py-1.5 text-sm text-[#43474F] disabled:pointer-events-none hover:bg-[#DADCE080] disabled:opacity-20"
                :disabled="!canCancel"
                @click.stop.prevent="cancelService()"
              >
                <X :size="18" />
                <p class="whitespace-nowrap">
                  {{ props.modelValue === "wp_cancel" ? t("global.cancel_request") : t("global.request_cancellation") }}
                </p>
                <ChevronRight :size="16" class="ml-auto" />
              </button>
              <button
                class="w-full flex cursor-pointer items-center gap-2 px-3 py-1.5 text-sm text-[#43474F] disabled:pointer-events-none hover:bg-[#DADCE080] disabled:opacity-20"
                :disabled="!canEdit"
                @click.stop.prevent="editService()"
              >
                <Pencil :size="16" />
                <p class="whitespace-nowrap">
                  {{ t("global.edit_services") }}
                </p>
                <ChevronRight :size="16" class="ml-auto" />
              </button>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
