import { get } from 'lodash'
import { defineStore } from 'pinia'
import type { CollectStatus } from '~/types/graphql-backend-types/gql-types'

export const filterStore = defineStore({
  id: 'filter',

  state: () => ({
    filters: {
      status: [] as Array<string>,
      client: [] as Array<string>,
    },
    pagination: {
      page: 1,
    },
  }),
  getters: {},
  actions: {
    // filters
    setFilter(filter: keyof typeof this.filters, value: Array<string>): void {
      this.filters[filter] = value
    },
    clearFilter(): void {
      this.filters = {
        status: [],
        client: [],
      }
    },
    getFilter(filter: string): Array<any> {
      return get(this.filters, filter, [])
    },

    // pagination
    setPagination(page: number): void {
      this.pagination.page = page
    },
    clearPagination(): void {
      this.pagination.page = 1
    },
    getPagination(): number {
      return get(this.pagination, 'page', 1)
    },
  },
})
