import { CollectStatus } from '~/types/graphql-backend-types/gql-types'

export const statusColor = [
  { status: CollectStatus.RequestSent, tag: '!bg-[#E6F7FF]', label: '!text-[#096DD9]' },
  { status: CollectStatus.Confirmed, tag: '!bg-[#F2F2FF]', label: '!text-[#3A1EE3]' },
  { status: CollectStatus.InTreatment, tag: '!bg-[#FAF5FF]', label: '!text-[#7E22CE]' },
  { status: CollectStatus.AdminInProgress, tag: '!bg-[#FFF7ED]', label: '!text-[#C2410C]' },
  { status: CollectStatus.HasIncident, tag: '!bg-[#FFF2F2]', label: '!text-[#B91C1C]' },
  { status: CollectStatus.Finished, tag: '!bg-[#ECFDF6]', label: '!text-[#067659]' },
  { status: CollectStatus.Cancelled, tag: '!bg-[#F9FAFB]', label: '!text-[#64748B]' },
]
