import type { CreateUserInput } from '~/types/graphql-backend-types/gql-types'
import type { User } from '~/types/user'
import { UserRoles } from '~/types/graphql-backend-types/gql-types'
import { CREATE_USER } from '~/queries/user'

export enum ClientType {
  recycler = 'recycler',
  producer = 'producer',
}

export const userStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'user',
  // a function that returns a fresh state
  state: () => ({
    user: {
      first_name: '',
      last_name: '',
      email: '',
      role: '',
      language: '',
      client_name: '',
      client_type: '',
      entity_name: '',
      id: '',
      picture_url: '',
      client_id: '',
      phone_number: '',
      client: {
        id: '',
        name: '',
      },
    } as User,
    stores_loaded: false,
    stores_loading_msg: 'Loading stores...',
  }),

  // optional getters
  getters: {
    getUser(): User {
      return this.user
    },
    getFullName(): string {
      return `${this.user.last_name} ${this.user.first_name}`
    },
    isAdmin(): boolean {
      return this.user.role === UserRoles.AdminApp
    },
    isRecycler(): boolean {
      return this.user.client.client_type === ClientType.recycler
    },
    isProducer(): boolean {
      return this.user.client.client_type === ClientType.producer
    },
  },
  // optional actions
  actions: {
    // TODO: Uncomment when update user backend side is done
    // async updateUser(input: UpdateClient) {
    //   const { data } = await apolloClientNest.mutate({
    //     mutation: UPDATE_USER,
    //     variables: { input }
    //   })

    //   this.user = data.updateClient;
    // },
    setStoresLoadingState(state: boolean = true) {
      this.stores_loaded = state
    },
    setStoresLoadingMsg(msg: string) {
      this.stores_loading_msg = msg
    },
    setClientType(client_type: string) {
      this.user.client_type = client_type
    },
    setClientRole(role: string) {
      this.user.role = role
    },
    loadUser(uid: string): void {
      const { getUser } = useUsersStore()
      if (uid) {
        const userFromStore: any = getUser(uid)
        const other_info: any = {
          language: '',
        }
        other_info.language = navigator.language.substring(0, 2)
        const user = { ...userFromStore, ...other_info }
        this.user = user
      }
    },
    async createUser(payload: CreateUserInput): Promise<{ data: any, validationError: any, errors: any }> {
      const { mutate } = useGqlMikro()

      const { data, validationError, errors } = await mutate({ mutation: CREATE_USER, variables: { input: payload } })
      return { data, validationError, errors }
    },
  },
})
