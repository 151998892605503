<script setup lang="ts">
import { userStore } from '~/stores/user'

const { t } = useI18n()

const state = reactive({
  isLoading: false,
  selectedClient: 0,
  url: '',
  rescheduleUrl: '',
})

const user: any = computed(() => {
  return userStore().user
})

const isProducer = computed(() => userStore().isProducer)
const isRecycler = computed(() => userStore().isRecycler)
const isAdmin = computed(() => userStore().isAdmin)

interface User {
  id: number
  name: string
  email: string
  role: string
  client: {
    id: number
    client_type: string
  }
}
</script>

<template>
  <HeaderMobile :title="t('global.services')" class="md:hidden" />
  <div
    v-if="user?.role === 'admin_app' || user?.role === 'user'"
    class="max-h-full min-h-full flex flex-1 flex-col bg-tertiary text-primary"
  >
    <div v-if="!state.isLoading" class="flex flex-col grow overflow-y-auto p-4 md:p-6">
      <Services v-if="isAdmin" />
      <ServicesRecycler v-else-if="isRecycler" />
      <ServicesProducer v-else-if="isProducer" />
    </div>
    <Loader v-else />
  </div>
  <div v-else class="w-full flex justify-center pt-4 text-red">
    <div>Unauthorized</div>
  </div>
</template>

<route lang="yaml">
meta:
  layout: home
  requiresAuth: true
</route>
